export default {
  languages: {
    us: 'English (United States)',
    es: 'Spanish (United States)',
    mx: 'Spanish (México)',
    co: 'Spanish (Colombia)',
    cl: 'Spanish (Chile)',
    pe: 'Spanish (Perú)',
    ec: 'Spanish (Ecuador)'
  },
  routes: {
    properties: 'homes-and-condos',
    projects: 'new-projects',
    blog: 'blog',
    blogSearch: 'search',
    category: 'c',
    categories: {
      present: 'present',
      qualityOfLife: 'quality-of-life',
      investment: 'investment',
      miamiTourism: 'miami-tourism'
    },
    institutional: 'pfs',
    calculator: 'mortgage-calculator',
    marketReport: 'market-report',
    county: 'county',
    account: 'account',
    profile: 'profile',
    savedProperties: 'saved-properties',
    savedSearches: 'saved-searches',
    notifications: 'notifications',
    resetPassword: 'reset-password',
    elite: 'elite',
    eliteThankYou: 'thank-you',
    eliteSocialLogin: 'social-login',
    test: 'test-page'
  },
  header: {
    signUp: 'Sign Up'
  },
  contactForm: {
    title: 'Invest in Miami',
    description:
      'Leave us your details and receive personalized advice on how to invest in\n      Miami'
  },
  menu: {
    home: 'Home',
    search: 'Search',
    developments: 'Developments',
    services: 'Services',
    aboutUs: 'About Us',
    blog: 'Blog',
    resources: 'Resources',
    contactUs: 'Contact',
    properties: 'Used Properties',
    newProperties: 'New Properties',
    buildings: 'Search by buildings',
    zone: 'Search by zone',
    inListing: 'PFS Listing',
    financing: 'Financing',
    financingLink: '/pfs/loans-for-foreigners',
    propertyManagement: 'Property Management',
    propertyManagementLink: '/pfs/property-management',
    accounting: 'Accounting, Insurance and Migration',
    accountingLink: '/pfs/accounting-and-legal-advice',
    insurance: 'Insurance',
    migration: 'Migration',
    rentInDollars: 'Rent in Dollars',
    rentInDollarsLink: '/pfs/rent-in-dollars',
    aboutUsLink: '/pfs/who-we-are',
    pfs: 'PFS',
    institutional: 'Institutional',
    contactUsLink: '/pfs/contact-us'
  },
  common: {
    translatedVersion: 'Spanish version',
    searchResults: 'Search results',
    date: 'Date',
    mightInterestYou: 'Might interest you:',
    bestHousesForSale: 'Best houses for sale',
    cookiesMessage:
      'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you agree with it.',
    closeCookiesMessage: 'Close cookies message popup',
    contactMenu: 'Contact',
    accountMenu: 'Account',
    signInSuccess: 'Signed up successfully',
    signUpSuccess: 'Signed up successfully',
    eliteSignInError: 'You must be an Elite member to access this page',
    accept: 'Accept',
    select: 'Select',
    on: 'On',
    off: 'Off',
    done: 'Done',
    goBack: 'Go back',
    goBackHome: 'Back to Home',
    delete: 'Delete',
    menu: 'Menu',
    autocomplete: 'Autocomplete',
    close: 'Close',
    closePopup: 'Close popup',
    closeFilters: 'Close filter',
    noResults: 'No results',
    noOptionsAvailable: 'No options available',
    range: '{0} to {1}',
    by: 'by {0}',
    fromN: 'From {0}',
    toN: 'To {0}',
    photoOf: 'Photo of {0}',
    errors: {
      unknown: 'Error'
    },
    viewMore: 'View more',
    viewLess: 'View less',
    oops: 'Oops!',
    noItemsFound: 'No items found',
    pageNotFound: 'The page you are looking for does not exist',
    tryAdjustingFilters: 'Try adjusting your filters',
    previous: 'Previous',
    next: 'Next',
    perMonthNumber: '/month',
    link: 'Link',
    yes: 'Yes',
    no: 'No',
    avgPrice: 'Avg. Price',
    avgPriceSqft: 'Avg. Price / SQFT',
    infoDisclaimer:
      "IDX information is provided exclusively for personal, non-commercial use, and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Information is deemed reliable but not guaranteed.\nThe listing broker's offer of compensation is made only to participants of  the MLS where the listing is filed."
  },
  elite: {
    menu: {
      launches: 'Launches',
      calendar: 'Miami Agenda'
    },
    auth: {
      login: 'Login',
      profile: 'User'
    },
    title: 'Elite Club - PFS Realty',
    description: 'Know the benefits of being part of the PFS Realty Elite Club',
    intro: `Investing in Miami is an excellent decision, having done so with <strong>PFS Realty Group</strong> opens the doors to great opportunities and benefits.`,
    welcome: 'Welcome to PFS Elite Club',
    benefits:
      'Learn about some of your <span class="font-bodoni">Benefits</span>',
    eventCalendar: '<span class="font-bodoni">Event Calendar</span>',
    pfsEventCalendar:
      '<span class="font-bodoni">Event Calendar</span> for PFS Realty Group',
    moreEvents: 'See the full schedule by <link>Clicking here</link>',
    footer: 'Copyright© 2024 PFS Realty Group. All rights reserved.',
    thankYouPage: {
      title: 'Thank you',
      subTitle: 'And welcome to PFS Elite Club',
      info: 'Very soon you will be able to enjoy all its benefits.'
    },
    socialLoginPage: {
      title: 'Sign in to PFS Elite Club with your social network'
    }
  },
  blog: {
    title: 'Opportunities in Miami and South Florida',
    description:
      'Opportunities in Miami and South Florida. Know the investment opportunities, to live or enjoy in Miami and South Florida.',
    subtitle:
      'Know the investment opportunities, to live or enjoy in Miami and South Florida.',
    contentTable: 'Table of contents',
    category: 'Category',
    categories: {
      present: 'Present',
      qualityOfLife: 'Quality of Life',
      investment: 'Investment',
      miamiTourism: 'Miami Tourism'
    },
    categoryArticles: 'Category articles',
    searchResults: 'Search results',
    searchHint: 'Adjust your search to find what you are looking for',
    relatedPosts: 'Related posts',
    seo: {
      title: "Blog {'|'} PFS Realty"
    }
  },
  auth: {
    title: 'Welcome to PFS',
    goToProfile: 'Go to profile',
    signIn: 'Sign in',
    signOut: 'Sign out',
    signUp: 'Sign up',
    forgotPassword: 'Forgot password',
    forgotPasswordHelp:
      'Enter your email and we will send you a link to reset your password.',
    forgotPasswordSubmit: 'Submit',
    forgotPasswordQuestion: 'Forgot password?',
    knowPasswordQuestion: 'Know your password?',
    forgotPasswordSuccess:
      'An email has been sent with instructions to reset your password.',
    passwordReset: 'Password reset',
    passwordResetSuccess: 'Password reset successfully',
    passwordResetError: 'This link has expired or is invalid.',
    passwordResetHelp: 'Enter your new password',
    invalidTicketHelp: 'Request a new link to change your password.',
    email: 'Email',
    emailPlaceholder: 'Enter email',
    emailRequired: 'Email is required',
    emailInvalid: 'Enter a valid email',
    password: 'Password',
    passwordPlaceholder: 'Enter password',
    createPasswordPlaceholder: 'Create password',
    passwordRequired: 'Password is required',
    passwordInvalid: 'Enter a valid password',
    loginToSavePropertyHint:
      'Sign in or create a new account to save a property',
    signInErrors: {
      '400': 'Internal error',
      '401': 'Invalid email or password',
      '403': 'You must verify your email',
      '500': 'Server error',
      unknown: 'Error'
    },
    signUpErrors: {
      '400': 'Internal error',
      '403': 'Invalid email or password',
      '409': 'Email already in use',
      '500': 'Server error',
      unknown: 'Error'
    },
    resetPasswordErrors: {
      '10': 'Invalid email',
      '40': 'User not found',
      '400': 'User not found',
      '403': 'Invalid email',
      '500': 'Server error',
      unknown: 'Error'
    },
    setNewPasswordErrors: {
      '10': 'Invalid email',
      '40': 'User not found',
      '400': 'User not found',
      '403': 'Invalid email',
      '500': 'Server error',
      unknown: 'Error'
    },
    submitSignIn: 'Sign in',
    submitSignUp: 'Submit',
    charactersAmountHint: 'At least 8 characters',
    characterMixHint: 'Mix letters and numbers',
    specialCharacterHint: 'At least one special character',
    signInDisclaimer:
      'By signing up you agree to our {0}terms and conditions{1}.',
    orConnectWith: 'Or connect with'
  },
  home: {
    seo: {
      title: 'PFS Realty Group',
      description:
        'At PFS Realty find real estate and properties for sale and rent in Florida.'
    },
    hero: {
      title: 'Homes and Condos for Sale and Rent in Miami',
      p: 'Properties',
      f: 'Financing',
      s: 'Services'
    },
    used: 'Used',
    new: 'New',
    rent: 'Rent',
    buy: 'Used',
    location: 'Search by city, ZIP code, or county',
    currentLocation: 'Current Location',
    contactFormTitle: 'Contact us for more information!',
    contactFormText:
      'Access the best investment opportunities, market updates, and specialized content.',
    find_property_title: 'Find your property in Miami',
    find_property_text:
      'We have a wide portfolio of properties that are perfect for you',
    properties_title: 'Our portfolio of properties',
    propertiesPortfolio: 'Homes and Condos for sale and rent in Miami',
    apartmentsPortfolioDescription: 'Our apartments portfolio',
    housesPortfolioDescription: 'Our houses portfolio',
    orlandoPortfolioDescription: 'Projects in Orlando',
    clients: {
      title:
        '2,000 clients from around the world have invested in Miami thanks to PFS',
      features: {
        one: {
          title:
            '21 years of experience in property sales in the United States',
          description:
            'Over two decades of experience in property sales in the United States, providing reliable service and successful results.'
        },
        two: {
          title: '65 advisors in 12 countries in the Americas and Europe',
          description:
            'A wide network of 65 advisors strategically located in the Americas and Europe to maximize your real estate investment opportunities.'
        },
        three: {
          title: 'More than 2,000 satisfied customers trust us',
          description:
            'More than 2,000 satisfied customers support our commitment to exceptional service and satisfactory results in real estate investments. Join us and experience success with PFS Realty.'
        }
      }
    },
    services: {
      title: 'Integrated Services',
      'property_title:': 'Acquire the Perfect Property',
      properties: {
        title: 'Acquire the Perfect Property',
        description:
          'Guaranteed purchase of new and high-quality used real estate. We have extensive experience and knowledge to find the ideal property.'
      },
      financing: {
        title: 'Customized Financing Solutions',
        description:
          'We offer flexible and personalized mortgage loans for foreigners with a minimum 30% down payment. We tailor the terms to your needs.'
      },
      extra_income: {
        title: 'Generate Extra Income through Renting',
        description:
          'Guaranteed property rental with tenants who generate income in dollars. We comprehensively manage to maximize profitability.'
      },
      management: {
        title: 'Property Management',
        description:
          'Professional care, preventive and corrective maintenance, and efficient management of your real estate investment. We optimize costs and benefits.'
      },
      legal: {
        title: 'Comprehensive Legal and Accounting Advisory',
        description:
          'Interdisciplinary team of highly specialized professionals in all legal and accounting aspects. We ensure regulatory compliance.'
      }
    },
    events: {
      title: 'Events',
      formMessage: 'Fill the form to reserve a spot in our event',
      registerButton: 'Book Entry'
    },
    testimonialsTitle: 'Testimonials',
    news_title: 'News',
    popupFormTitle:
      'Discover the secret to achieving your real estate dream in Miami and Orlando!',
    popupFormMessage:
      "Subscribe to our newsletter and gain access to exclusive tips, market trends, and unique opportunities. Don't miss this chance to boost your success in real estate!"
  },
  account: {
    accountSettings: 'Account Settings',
    profile: 'Profile',
    profileHint: 'Customize your account and update your login preferences',
    notifications: 'Notifications',
    notificationsSectionHint: 'Manage your notifications preferences',
    notificationsHint: 'Turn on or off notifications preferences',
    searchNotificationsHint: 'Turn on notifications for this search',
    enableAllNotifications: 'Enable all notifications',
    propertyNotificationsEnabled: 'Notifications enabled for this property',
    propertyNotificationsDisabled: 'Notifications disabled for this property',
    savedProperties: 'Saved Properties',
    savedPropertiesHint:
      "Keep track of the homes you like and the ones you've seen recently.",
    toggleNotificationsHint:
      'Use the toggle switch to turn on or off notifications',
    savedSearches: 'Saved Searches',
    recentlyViewedProperties: 'Recently Viewed',
    personalInfo: 'Personal Information',
    name: 'Name',
    namePlaceholder: 'Enter your name',
    lastName: 'Last Name',
    lastNamePlaceholder: 'Enter your last name',
    picture: 'Picture',
    pictureHint: 'Upload a profile picture',
    accessAndSecurity: 'Access and Security',
    email: 'Email',
    emailPlaceholder: 'Enter your email',
    password: 'Password',
    passwordPlaceholder: 'Enter your password',
    noSavedProperties: 'Get updates for your saved properties',
    searchProperties: 'Search Properties',
    untitledSavedSearch: 'Untitled search',
    noSavedSearches: 'Get updates for your saved searches',
    savedSearchesHint:
      'Saving your searches saves you time and keeps you informed of new updates.',
    changePasswordEmailWithButton:
      'Change your password through your email {button}.',
    changePasswordEmailButton: 'clicking here',
    changePasswordEmailSent:
      'An email has been sent to {email} with instructions to change your password.'
  },
  search: {
    previous: 'Previous',
    next: 'Next',
    title: 'Search for homes, condos and other real estate',
    searchPlaceholder: 'Search by city, zip code or county',
    searchLabel: 'Search',
    nResults: '{0} results',
    nActiveProperties: '{0} active',
    resetFilters: 'Reset search',
    resetFiltersLong: 'Remove filters',
    acceptFilters: 'Apply',
    saveSearch: 'Save search',
    searchSaved: 'Search saved',
    map: 'Map',
    listView: 'List',
    county: 'County',
    city: 'City',
    zipcode: 'ZIP code',
    neighborhood: 'Neighborhood',
    allCounties: 'All counties',
    allCities: 'All cities',
    allZipcodes: 'All ZIP codes',
    allNeighborhoods: 'All neighborhoods',
    from: 'From',
    to: 'To',
    noMin: 'No min.',
    noMax: 'No max.',
    bedroomsCard: 'be',
    bathsFullCard: 'ba',
    bedroomsShort: 'be',
    bathsFullShort: 'ba',
    areaShort: 'sqft',
    areaOf: 'area of',
    postalCodeShort: 'ZIP code {0}',
    filters: 'Filters',
    propertyType: 'Home type',
    propertyTypes: 'Home types',
    selectPropertyType: 'Select home type',
    selectHouseType: 'Select type',
    selectCondoType: 'Select type',
    selectPropertySubTypes: 'Select type',
    property: 'Property',
    properties: 'Homes and condos',
    projects: 'Projects',
    houseType: 'Home',
    housesType: 'Homes',
    condoType: 'Apartment',
    condosType: 'Apartments',
    apartmentsCondoType: 'Apartments condominium',
    housesCondoType: 'Houses condominium',
    condosCondosType: 'Apartments condominiums',
    housesCondosType: 'Houses condominiums',
    house: 'House',
    houses: 'Houses',
    singleFamilyResidence: 'Single Family',
    singleFamilyResidences: 'Single Families',
    townhouse: 'Townhouse',
    townhouses: 'Townhouses',
    villa: 'Villa',
    villas: 'Villas',
    condominium: 'Condo',
    condominiums: 'Condos',
    apartment: 'Apartment',
    apartments: 'Apartments',
    multiFamily: 'Multi Family',
    multiFamilies: 'Multi Families',
    building: 'Building',
    buildings: 'In Buildings',
    price: 'Price',
    priceFrom: 'From',
    priceTo: 'To',
    priceFromShort: 'Min. price',
    priceToShort: 'Max. price',
    areas: 'areas',
    bedrooms: 'Beds',
    bedroomsFrom: 'From',
    bedroomsTo: 'To',
    nOrMoreBedrooms: '{0} or more beds',
    nOrLessBedrooms: 'up to {0} beds',
    nBedrooms: '{0} beds',
    nRooms: '{0} rooms',
    moreOptions: 'More options',
    showPlaces: 'Show places',
    bedroomsAndBathrooms: 'Beds & Baths',
    bathrooms: 'Baths',
    bathroomsFrom: 'From',
    bathroomsTo: 'To',
    nOrMoreBathrooms: '{0} or more baths',
    nOrLessBathrooms: 'up to {0} baths',
    nBathrooms: '{0} baths',
    nArea: '{0} sqft.',
    nAreaShort: '{0} sqft.',
    nAreaM2: '{0} m²',
    nStories: '{0} stories',
    nYear: '{0}',
    area: 'Area',
    areaFrom: 'Area from',
    areaTo: 'Area to',
    year: 'Year',
    yearFrom: 'Year from',
    yearTo: 'Year to',
    furnished: 'Furnished',
    view: 'View',
    viewOptions: {
      any: 'Any',
      ocean: 'Ocean'
    },
    condoCost: 'HOA Max',
    condoCostFrom: 'Condo cost from',
    condoCostTo: 'Condo cost to',
    hopa: 'HOPA',
    dock: 'Dock',
    shortTermLease: 'Short-term lease',
    inListing: 'In listing',
    inListingItems: '{0} in listing',
    pendingAndUnderContract: 'Pending & Under contract',
    pendingAndUnderContractProperties: 'Pending & Under contract properties',
    advancedSearch: 'Advanced search',
    viewMap: 'View map',
    viewSatellite: 'View satellite',
    viewBuildings: 'View buildings',
    view2D: '2D',
    view3D: '3D',
    sortBy: 'Sort',
    visitSite: 'Visit website',
    mapLayers: {
      newProjects: 'New projects',
      beaches: 'Beaches',
      malls: 'Malls',
      restaurants: 'Restaurants',
      collegeUniversities: 'Universities',
      publicSchools: 'Public Schools',
      privateSchools: 'Private Schools',
      hospitals: 'Hospitals'
    },
    sortByOptions: {
      mostRecent: 'Most recent',
      oldest: 'Oldest',
      highestPrice: 'Highest price',
      lowestPrice: 'Lowest price',
      fewestBathrooms: 'Fewest bathrooms',
      mostBathrooms: 'Most bathrooms',
      fewestBedrooms: 'Fewest bedrooms',
      mostBedrooms: 'Most bedrooms',
      smallestArea: 'Smallest area',
      largestArea: 'Largest area',
      firstToFinish: 'First to finish',
      lastToFinish: 'Last to finish',
      counties: 'Counties',
      cities: 'Cities'
    },
    buyTypeOptions: {
      used: 'Used',
      new: 'New Constructions',
      rent: 'For Rent',
      buy: 'For Sale',
      sold: 'Sold',
      rented: 'Rented'
    },
    hopaOptions: {
      any: 'Any',
      'hopa-eligible': 'HOPA eligible',
      'no-hopa': 'Non-HOPA'
    },
    findItems: 'Find {items}',
    availableItems: '{items} for sale and rent',
    soldItems: 'Sold {items}',
    soldItems_female: 'Sold {items}',
    rentedItems: 'Rented {items}',
    rentedItems_female: 'Rented {items}',
    newItems: 'New {items} for sale',
    newItems_female: 'New {items} for sale',
    usedItems: 'Used {items} for sale',
    usedItems_female: 'Used {items} for sale',
    itemsForSale: '{items} for sale',
    itemsForRent: '{items} for rent',
    rentSearchResults: '{search} {items} for Rent',
    buySearchResults: '{search} {items} for Sale',
    soldSearchResults: '{search} Sold {items}',
    rentedSearchResults: '{search} Rented {items}',
    buyRentSearchResults: '{search} {items} for Sale and Rent',
    rentSearchResultsNearYou: '{0} for Rent near you',
    buySearchResultsNearYou: '{0} for Sale near you',
    soldSearchResultsNearYou: 'Sold {0} near you',
    rentedSearchResultsNearYou: 'Rented {0} near you',
    buyRentSearchResultsNearYou: '{0} for Sale and Rent near you',
    rentResults: 'Rental Listing',
    soldResults: 'Sold Properties',
    rentedResults: 'Rented Properties',
    buyResults: 'Properties for sale',
    anyResults: 'Available Properties',
    all: 'All',
    otherItems: 'Nearby properties',
    otherSearches: 'Other people also searched for',
    removeBoundary: 'Remove boundary',
    addBoundary: 'Add new boundary',
    cancelBoundaryDraw: 'Cancel',
    otherCities: 'Other cities',
    sortByCity: 'Cities',
    tableSortOptions: {
      unitNumberAsc: 'Unit # low to high',
      unitNumberDesc: 'Unit # high to low',
      priceLowHigh: 'Price low to high',
      priceHighLow: 'Price high to low',
      bedroomsLowHigh: 'Bedrooms low to high',
      bedroomsHighLow: 'Bedrooms high to low',
      bathroomsLowHigh: 'Bathrooms low to high',
      bathroomsHighLow: 'Bathrooms high to low',
      areaLowHigh: 'Area low to high',
      areaHighLow: 'Area high to low',
      dateNewOld: 'Newest to oldest',
      dateOldNew: 'Oldest to newest'
    }
  },
  paramsKeys: {
    propertyType: 'pt',
    propertySubType: 'ps',
    projectType: 'pj',
    price: 'pr',
    bedrooms: 'be',
    bathsFull: 'ba',
    area: 'a',
    year: 'y',
    furnished: 'f',
    view: 'v',
    condoCost: 'cc',
    dock: 'd',
    shortTermLease: 'stl',
    excludePendingAndUnderContract: 'epc',
    inListing: 'il',
    sortBy: 's',
    buyType: 'bt',
    page: 'pg',
    location: 'l',
    county: 'co',
    city: 'ci',
    zipcode: 'zc',
    neighborhood: 'n',
    geolocation: 'g',
    usedProject: 'up',
    hopa: 'h'
  },
  legacyParamsKeys: {
    propertyType: 'type',
    propertySubType: 'properties',
    projectType: 'projects',
    price: 'price',
    bedrooms: 'bedrooms',
    bathsFull: 'bathrooms',
    area: 'area',
    year: 'year',
    furnished: 'furnished',
    view: 'view',
    condoCost: 'hoa',
    dock: 'dock',
    shortTermLease: 'short-term-lease',
    excludePendingAndUnderContract: 'exclude-pending-and-under-contract',
    inListing: 'in-listing',
    sortBy: 'sort',
    buyType: 'type',
    page: 'page',
    location: 'search',
    county: 'county',
    city: 'city',
    zipcode: 'zipcode',
    neighborhood: 'neighborhood',
    geolocation: 'gl',
    usedProject: 'used',
    hopa: 'hopa'
  },
  paramsAliases: {
    RES: 'buy',
    RNT: 'rent',
    SOL: 'sold',
    RTD: 'rented',
    house: 'homes',
    singleFamilyResidence: 'single-families',
    townhouse: 'townhouses',
    villa: 'villas',
    condo: 'condos',
    apartment: 'apartments',
    building: 'in-buildings',
    multiFamily: 'multi-families',
    'pre-construction': 'pre-construction',
    'under-construction': 'under-construction',
    built: 'built',
    'on-the-market': 'on-the-market',
    'hopa-eligible': 'hopa',
    'no-hopa': 'no-hopa',
    'yearBuild:desc': 'most-recent',
    'yearBuild:asc': 'oldest',
    'listPrice:desc': 'highest-price',
    'listPrice:asc': 'lowest-price',
    'bathsFull:desc': 'most-bathrooms',
    'bathsFull:asc': 'fewest-bathrooms',
    'bedrooms:desc': 'most-bedrooms',
    'bedrooms:asc': 'fewest-bedrooms',
    'propertyArea:desc': 'largest-area',
    'propertyArea:asc': 'smallest-area',
    'yearCompletion:desc': 'last-to-finish',
    'yearCompletion:asc': 'first-to-finish',
    buildingsInCities: 'cities-table',
    city: 'cities',
    availability: 'availability',
    pending: 'pending',
    closed: 'closed',
    statistics: 'statistics'
  },
  locationTypes: {
    county: 'County',
    city: 'City',
    zip_code: 'Zip code',
    project: 'Project',
    building: 'Building',
    address: 'Address',
    neighborhood: 'Neighborhood',
    mls_id: 'MLS ID'
  },
  property: {
    save: 'Save',
    saved: 'Saved',
    propertySaved: 'Property saved as favorite',
    propertyRemoved: 'Property removed from favorites',
    backToSearch: 'Back to results',
    backToProject: 'Back to building',
    share: 'Share',
    copyShareLink: 'Copy link',
    previousProperty: 'Previous',
    nextProperty: 'Next',
    bedrooms: 'Beds',
    bathrooms: 'Baths',
    factsAndFeatures: 'Facts & Features',
    interior: 'Interior',
    bedroomsAndBathrooms: 'Bedrooms & Bathrooms',
    bedroomsLong: 'Bedrooms',
    bathroomsLong: 'Bathrooms',
    fullBathrooms: 'Full bathrooms',
    flooring: 'Flooring',
    heating: 'Heating',
    heatingFeatures: 'Heating features',
    cooling: 'Cooling',
    hasCooling: 'Has cooling',
    coolingFeatures: 'Cooling features',
    appliances: 'Appliances',
    appliancesIncluded: 'Appliances included',
    interiorFeatures: 'Interior features',
    otherInteriorFeatures: 'Other interior features',
    virtualTour: 'Virtual tour',
    property: 'Property',
    parking: 'Parking',
    parkingFeatures: 'Parking features',
    poolFeatures: 'Pool features',
    pool: 'Pool',
    privatePool: 'Private pool',
    exteriorFeatures: 'Exterior features',
    patioAndPorchFeatures: 'Patio & Porch details',
    viewDescription: 'View description',
    otherPropertyInformation: 'Other property information',
    parcelNumber: 'Parcel number',
    attachedToAnotherStructure: 'Attached to another structure',
    otherEquipment: 'Other equipment',
    construction: 'Construction',
    typeAndStyle: 'Type & style',
    homeType: 'Home type',
    materialInformation: 'Material information',
    cibstructionMaterials: 'Construction materials',
    condition: 'Condition',
    propertyCondition: 'Property condition',
    newConstruction: 'New construction',
    type: 'Type',
    yearBuilt: 'Year built',
    communityAndNeighborhood: 'Community & Neighborhood',
    security: 'Security',
    location: 'Location',
    region: 'Region',
    neighborhood: 'Neighborhood',
    hoaAndFinancial: 'HOA & Financial',
    hoa: 'HOA',
    hasHoa: 'Has HOA',
    hoaFee: 'HOA fee',
    amenitiesIncluded: 'Amenities included',
    otherFinancialInformation: 'Other financial information',
    buyerAgencyCompensation: 'Buyer agency compensation',
    contactAgent: 'Contact agent',
    requestShowing: 'Request showing',
    similarProperties: 'Similar properties',
    shareTitle: 'Property on PFS',
    shareText: 'Check out this property on PFS',
    shareLinkCopied: 'Link copied to clipboard',
    areaSqft: 'Area (sqft.)',
    mlsCode: 'MLS #',
    sold: 'Sold',
    sold_female: 'Sold',
    rented: 'Rented',
    rented_female: 'Rented',
    active: 'Active',
    active_female: 'Active',
    estimatedPayment: 'Estimated Payment',
    getPrequalification: 'Calculate your monthly payments'
  },
  project: {
    newProjects: 'New projects',
    usedBuildings: 'Used buildings',
    project: 'Project',
    priceSQFT: 'Price per sqft',
    priceSQFTShort: '$/SQFT',
    SQFT: 'SQFT',
    SQFTShort: 'SQFT',
    preConstruction: 'Pre-construction',
    underConstruction: 'Under construction',
    built: 'Built',
    onTheMarket: 'On the market',
    profile: 'Profile',
    availability: 'Availability',
    pending: 'Pending',
    closed: 'Closed',
    stats: 'Statistics',
    projectDetails: 'Project details',
    propertyDetails: 'Property details',
    floorPlans: 'Floor plans',
    features: 'Features',
    amenities: 'Amenities',
    services: 'Servicios',
    exteriorAspects: 'Exterior Aspects',
    propertyDescriptions: 'Property and Area Descriptions',
    location: 'Location Details',
    layout: 'Layout',
    salesPolicies: 'Sales Policies',
    financing: 'Financing',
    deliveryStages: 'Delivery Stages',
    appliance: 'Appliances',
    heating: 'Heating',
    maintenanceFee: 'Maintenance fee',
    construction: 'Construction',
    garage: 'Garage',
    restrictions: 'Restrictions',
    exterior: 'Exterior',
    parking: 'Parking',
    termsConsidered: 'Terms considered',
    interior: 'Interior',
    petsAllowed: 'Pets allowed',
    flooring: 'Flooring',
    furnished: 'Furnished',
    HOAFee: 'HOA Fee/Quarterly',
    HOPA: '+55 communities',
    hopaEligible: 'Eligible',
    noHopa: 'Non-HOPA',
    cooling: 'Cooling',
    yearBuilt: 'Year built',
    similarProjects: 'Similar projects',
    yearCompletionShort: 'Est. completion',
    stories: 'Stories',
    residences: 'Residences',
    shareTitle: 'Project on PFS',
    shareText: 'Check out this project on PFS',
    shareLinkCopied: 'Link copied to clipboard',
    areaSqft: 'Area (sqft.)',
    sales: 'Sales',
    rentals: 'Rentals',
    unitNumberShort: 'Unit#',
    listPrice: 'List price',
    maintenanceExpenseShort: 'Maint$',
    listDate: 'List date',
    availableCondosForRent: 'Available condos for rent',
    pendingCondosForRent: 'Pending condos for rent',
    closedCondosForRent: 'Rented condos',
    availableCondosForSale: 'Available condos for sale',
    pendingCondosForSale: 'Pending condos for sale',
    closedCondosForSale: 'Sold condos',
    noCondos: '{project} has no condos available with the selected filters',
    condos:
      '{project} has {n} condo(s) with the selected filters with an average price/sqft of {price}',
    statsLabels: {
      averageListPricePerSQFT: 'Average List Price / SQFT',
      minListPricePerSQFT: 'Min List Price / SQFT',
      maxListPricePerSQFT: 'Max List Price / SQFT',
      monthsOfInventory: 'Months of Inventory',
      unitsSoldPast3Months: 'Units Sold within the past 3 months',
      unitsSoldPast6Months: 'Units Sold within the past 6 months',
      unitsSoldPast12Months: 'Units Sold within the past 12 months',
      unitsSoldPast24Months: 'Units Sold within the past 24 months',
      availableUnitsForSale: 'Available Units For Sale',
      TotalUnitsAvailableForSaleP: '% of Total Units Available For Sale',
      minSalesPricePerSQFT_6Months: 'Min Sales Price / SQFT (6 months)',
      maxSalesPricePerSQFT_6Months: 'Max Sales Price / SQFT (6 months)',
      averageSalesPricePerSQFT_3Months: 'Average Sales Price / SQFT (3 months)',
      averageSalesPricePerSQFT_6Months: 'Average Sales Price / SQFT (6 months)',
      averageSalesPricePerSQFT_12Months:
        'Average Sales Price / SQFT (12 months)',
      averageSalesPricePerSQFT_24Months:
        'Average Sales Price / SQFT (24 months)',
      availableUnitsForRent: 'Available Units For Rent',
      totalUnitsAvailableForRentP: '% of Total Units Available For Rent',
      unitsRentedPast3Months: 'Units Rented within the past 3 months',
      unitsRentedPast6Months: 'Units Rented within the past 6 months',
      unitsRentedPast12Months: 'Units Rented within the past 12 months',
      unitsRentedPast24Months: 'Units Rented within the past 24 months',
      avgSalesPriceSqft: 'Average Sales Price / SQFT',
      avgRentalsPriceSqft: 'Average Rentals Price / SQFT',
      year: 'Year',
      soldUnits: 'Sold units',
      rentedUnits: 'Rented units',
      nSoldUnits: '{0} sold units',
      informationReliability: 'Information deemed reliable but not guaranteed.'
    }
  },
  calculator: {
    title: 'Mortgage Calculator',
    description:
      'This tool allows you to simulate the monthly payment of a mortgage loan, considering factors such as principal and interest, property tax, administration fee, and monthly insurance. \n' +
      'By entering data about the property price, down payment, and interest rate, you can get an accurate calculation of your monthly payments, facilitating the purchase and financing of your home through a financial institution. \n',
    hint: 'This is essential for your financial education and planning the purchase of your own home, allowing you to better manage your credit history and savings.',
    monthlyPayments: 'Monthly Payments',
    principalInterest: 'Principal & Interest',
    propertyTax: 'Property Tax',
    associationFee: 'Association Fee',
    principalInterestLegend: 'Principal & Interest',
    propertyTaxLegend: 'Property Tax',
    associationFeeLegend: 'Association Fee',
    insuranceFee: 'Insurance Fee',
    homePrice: 'Home Price',
    propertyTaxYearly: 'Property Tax (yearly)',
    associationFeeMonthly: 'Association Fee (per month)',
    insuranceFeeMonthly: 'Insurance Fee (per month)',
    downPayment: 'Down Payment (%)',
    interestRate: 'Interest Rate (%)',
    interestRatePlaceholder: 'Interest Rate',
    loanType: 'Loan Type',
    loanType10Year: '10-Year Fixed',
    loanType15Year: '15-Year Fixed',
    loanType20Year: '20-Year Fixed',
    loanType30Year: '30-Year Fixed',
    disclaimer: '*The calculations are an estimate.'
  },
  footer: {
    navigationLinks: 'Navigation Links',
    city_label: 'City',
    zip_code_label: 'Zip code',
    county_label: 'County',
    pre_construction_label: 'Preconstruction',
    home_type_label: 'Home type',
    price_label: 'Sale price',
    rent_label: 'Rent price',
    other_cities: 'Other cities',
    other_zipcodes: 'Other zip codes',
    other_types: 'Other types',
    other_counties: 'Other counties',
    other_preconstructions: 'Other preconstructions',
    other_prices: 'Other prices',
    findUs: 'Find us:',
    salesTitle: 'Representatives in:',
    countries:
      'MÉXICO • COLOMBIA • ARGENTINA • SPAIN • VENEZUELA • ECUADOR • CHILE\n            • PERU • CANADA • PORTUGAL',
    interest: 'Of interest',
    termsAndConditions: 'Terms and Conditions',
    termsAndConditionsLink: '/pfs/terms-and-conditions',
    whoWeAre: 'Who We Are',
    allRightsReserved: 'All rights reserved'
  },
  connectors: {
    in: '{0} in {1}',
    with: '{0} with {1}',
    without: '{0} without {1}',
    from: '{0} from {1}',
    to: '{0} to {1}',
    and: '{0} and {1}',
    or: '{0} or {1}',
    of: '{0} of {1}',
    ranging: '{0} ranging {1}',
    ofType: '{0} of type {1}',
    ofProperty: '{1} {0}',
    type: '{0} of type {1}',
    locatedIn: '{0} located in {1}',
    locatedIn_female: '{0} located in {1}',
    builtIn: '{0} built in {1}',
    builtIn_female: '{0} built in {1}',
    default: '{1} {0}',
    withView: '{0} with {1} view',
    withViewWith: '{0} with view with {1}',
    withViewTo: '{0} with view to {1}',
    withViewToThe: '{0} with view to the {1}',
    withViewToThe_female: '{0} with view to the {1}',
    withViewToThe_female_plural: '{0} with view to the {1}',
    recently: 'Recently {0}'
  },
  institutional: {
    seoTitle: 'PFS Realty Group',
    title: 'Opportunities in Miami and South Florida',
    description:
      'Know the investment opportunities, to live or enjoy in Miami and South Florida.'
  },
  marketReport: {
    title: 'Market Report',
    description:
      'Get the latest real estate market reports in Miami and South Florida.',
    longTitle: 'Real Estate Market Report in South Florida - PFS Realty Group',
    help: 'Select the area, the type of property, and the rest of the options to see the updated information',
    activeListings: 'Active Listings',
    nActiveListingsIn: '{count} Active Properties in {location}',
    nActiveListings: '{0} Active Properties in all counties',
    recentlySold: 'Recently Sold',
    recentlyRented: 'Recently Rented',
    recentlyClosed: 'Recently Closed',
    priceTrends: 'Price Trends',
    averagePrice: 'Average Prices in {0}',
    buyTypes: {
      sales: 'Sales',
      rentals: 'Rentals'
    }
  },
  pages: {
    neighborhoodsIn: 'Neighborhoods in {0}',
    citiesIn: 'Cities in {0}',
    counties: 'Counties'
  },
  seo: {
    locale: 'us',
    alternateLocale: 'mx',
    defaultTitle: 'PFS Realty Group',
    defaultDescription:
      'At PFS Realty find real estate and properties for sale and rent in Florida.',
    home: 'Home',
    defaultSingle: 'House or apartment',
    singleSale: '{0} for sale',
    singleRent: '{0} for rent',
    singleSaleAlt: '{0} for sale',
    singleRentAlt: '{0} for rent',
    defaultMultiple: 'Homes and condos',
    multipleSale: 'Homes and condos for sale',
    multipleRent: 'Homes and condos for rent',
    project: 'Project',
    projects: 'Projects',
    preConstruction: '{0} in preconstruction',
    underConstruction: '{0} under construction',
    built: 'New {0}',
    onTheMarket: '{0} on the market',
    complementaryTitle:
      'at PFSRealty find real estate and properties for sale and rent.',
    complementaryDescription:
      ' with PFS Realty Group. Discover the best investment opportunities in Miami with PFS Realty Group. Contact us today!',
    atFlorida: 'at Florida',
    numberArticle: '{0}',
    numberArticleSingle: '{0}',
    yearBuilt: 'Built in {0}',
    yearCompletion: 'Est. completion {0}',
    nResidences: '{0} Residences',
    exclusive: 'Exclusive {0}',
    exclusive_female: 'Exclusive {0}',
    mlsId: '#MLS {0}',
    hasPhotos: 'PFS Realty Group has {1} photos of this {0}',
    hasPhotos_female: 'PFS Realty Group has {1} photos of this {0}',
    counties: {
      longTitle: 'Counties in Florida - PFS Realty Group',
      title: 'Counties in Florida',
      description:
        'Find homes and condos for sale and rent in counties of Florida.'
    },
    cities: {
      longTitle: 'Cities in {0} - PFS Realty Group',
      title: 'Cities in {0}',
      description: 'Find homes and condos for sale and rent in cities of {0}.'
    },
    neighborhoods: {
      longTitle: 'Neighborhoods in {0} - PFS Realty Group',
      title: 'Neighborhoods in {0}',
      description:
        'Find homes and condos for sale and rent in neighborhoods of {0}, Florida.'
    },
    calculator: {
      longTitle: 'Mortgage Calculator - PFS Realty Group',
      description:
        'Easily calculate your mortgage payments for properties in Florida with our online tool.'
    },
    discover: 'Discover {0}',
    discover_female: 'Discover {0}'
  }
};
