export default {
  home: {
    seo: {
      title: 'Bienes raíces en Miami: compra, arriendo y financiamiento',
      description:
        'Descubre bienes raíces en Miami con asesoría experta de PFS Realty. Compra, arrienda o invierte con financiamiento flexible y personalizado.'
    },
    hero: {
      title: 'Bienes raíces en venta y arriendo en Miami',
      p: 'Propiedades',
      f: 'Financiamiento',
      s: 'Servicios'
    },
    used: 'Usado',
    new: 'Nuevo',
    rent: 'Arrendar',
    buy: 'Usado',
    location: 'Ciudad, código postal o condado',
    currentLocation: 'Ubicación actual',
    contactFormTitle:
      '¡Contacta a uno de nuestros asesores para más información!',
    contactFormText:
      'Accede a nuestra asesoría gratuita de servicios 360° y obtén hasta un 70% de financiamiento en tu inversión.',
    properties_title: 'Nuestro portafolio de propiedades',
    propertiesPortfolio: 'Departamentos y Casas en venta y arriendo en Miami',
    apartmentsPortfolioDescription: 'Nuestro portafolio de departamentos',
    housesPortfolioDescription: 'Nuestro portafolio de casas',
    find_property_title: 'ENCUENTRA TU PROPIEDAD POR CIUDAD',
    find_property_text:
      'Tenemos un amplio portafolio de propiedades perfectas para ti.',
    clients: {
      title:
        '2.000 clientes en todo el mundo han invertido en Miami gracias a PFS Realty',
      features: {
        one: {
          title: '22 años de experiencia en ventas de propiedades en EE. UU.',
          description:
            'Nuestra prolongada trayectoria como líderes en el mercado de bienes raíces en Miami nos posiciona como tu mejor opción. Tenemos un historial comprobado que respalda nuestro servicio confiable y resultados exitosos.'
        },
        two: {
          title: '65 agentes en 12 países de América y Europa',
          description:
            'Con una red internacional de agentes, ofrecemos perspectivas globales y localizadas que enriquecen nuestra capacidad para encontrar oportunidades inigualables. Desde el asesoramiento hasta el cierre del trato, optimizamos tus oportunidades de inversión inmobiliaria.'
        },
        three: {
          title: 'Más de 2.000 clientes satisfechos confían en nosotros',
          description:
            'La confianza de nuestros clientes es el reflejo de la dedicación y conocimiento del mercado de bienes raíces. Somos una de las inmobiliarias en Miami más confiables y respetadas. ¡Únete y experimenta el éxito con PFS Realty!'
        }
      }
    },
    services: {
      title: 'Servicios integrales',
      properties: {
        title: 'Adquiere la propiedad ideal',
        description:
          'Encuentra propiedades destacadas en Miami y compra inmuebles nuevos y usados de alta calidad. Nuestros agentes inmobiliarios cuentan con amplia experiencia y conocimiento para brindarte la atención y el asesoramiento especializado que necesitas.'
      },
      financing: {
        title: 'Financiamiento a tu medida',
        description:
          'La compra de una propiedad suele requerir de un financiamiento sólido y adaptado a tu capacidad económica. Por eso te ofrecemos créditos hipotecarios flexibles y personalizados para chilenos con mínimo 30% de entrada. Adaptamos las condiciones a tus necesidades.'
      },
      extra_income: {
        title: 'Genera ingresos extras alquilando',
        description:
          'Te brindamos una asistencia integral para transformar tu inmueble en una fuente de ingresos en dólares, manejando todas las etapas del arrendamiento para maximizar un retorno de inversión óptimo.'
      },
      management: {
        title: 'Administración de viviendas',
        description:
          'Contamos con un equipo preparado para el cuidado profesional y el mantenimiento preventivo y correctivo de tu vivienda. Optimizamos costos y beneficios para asegurar una administración eficiente en tu inversión inmobiliaria.'
      },
      legal: {
        title: 'Asesoría legal y contable integral',
        description:
          'Nuestro equipo interdisciplinario de expertos proporciona asesoría para aclararte cualquier duda sobre los aspectos legales y contables que involucran la compraventa y arriendo de bienes inmuebles. Aseguramos el cumplimiento normativo.'
      }
    },
    events: {
      title: 'Próximos eventos',
      formMessage: 'Déjanos tus datos para separar un cupo en nuestro evento',
      registerButton: 'Reserva Entrada'
    },
    testimonialsTitle: 'Testimoniales',
    news_title: 'Nuestro Blog',
    popupFormTitle:
      'Suscríbete a nuestro newsletter y obtén información de primera mano sobre el mercado inmobiliario en Miami y Orlando.',
    popupFormMessage:
      'Consejos exclusivos, tendencias del mercado y posibilidades únicas. ¡No te pierdas esta oportunidad para impulsar tu éxito en bienes raíces!'
  },
  search: {
    title: 'Búsqueda de casas, departamentos y otros inmuebles',
    properties: 'Casas y departamentos',
    condoType: 'Departamento',
    condosType: 'Departamentos',
    apartmentsCondoType: 'Condominio de departamentos',
    apartment: 'Departamento',
    apartments: 'Departamentos',
    buyTypeOptions: {
      rent: 'Arrendar',
      rented: 'Arrendado | Arrendados'
    },
    availableItems: '{items} en venta y arriendo',
    rentedItems: '{items} arrendados',
    rentedItems_female: '{items} arrendadas',
    soldOrRentedItems: '{items} vendidos o arrendados',
    soldOrRentedItems_female: '{items} vendidas o arrendadas',
    itemsForRent: '{items} en arriendo',
    rentSearchResults: '{items} en arriendo en {search}',
    rentedSearchResults: '{items} arrendadas en {search}',
    buyRentSearchResults: '{items} en venta y arriendo en {search}',
    rentSearchResultsNearYou: '{0} en arriendo cerca de tu ubicación',
    rentedSearchResultsNearYou: '{0} arrendadas cerca de tu ubicación',
    buyRentSearchResultsNearYou:
      '{0} en venta y arriendo cerca de tu ubicación',
    rentResults: 'Propiedades en arriendo',
    rentedResults: 'Propiedades arrendadas'
  },
  property: {
    rented: 'Arrendado',
    rented_female: 'Arrendada'
  },
  project: {
    rentals: 'Arriendos',
    availableCondosForRent: 'Departamentos disponibles para arrendar',
    pendingCondosForRent: 'Departamentos pendientes para arrendar',
    closedCondosForRent: 'Departamentos arrendados',
    availableCondosForSale: 'Departamentos disponibles para la venta',
    pendingCondosForSale: 'Departamentos pendientes para la venta',
    closedCondosForSale: 'Departamentos vendidos',
    condos:
      '{project} tiene {n} departamento(s) con las opciones seleccionadas con un precio por pie cuadrado promedio de {price}'
  },
  footer: {
    rent_label: 'Precio de Arriendo'
  },
  calculator: {
    title: 'Simulador crédito hipotecario',
    description:
      'Esta herramienta te permite evaluar el pago mensual de un crédito hipotecario, considerando factores como el dividendo hipotecario, las contribuciones anuales, los gastos comunes y el seguro mensual. <br/>' +
      'Al ingresar la información sobre el precio del inmueble, el pie y la tasa de interés, obtendrás un cálculo preciso de tus cuotas mensuales, facilitando la compra y el financiamiento de tu propiedad a través de una institución financiera.  <br/>',
    hint: 'Esto es esencial para la educación financiera y la planificación de la compra de vivienda propia, permitiéndote gestionar mejor tu historial crediticio y ahorros.',
    monthlyPayments: 'Cuotas mensuales',
    principalInterest: 'Dividendo hipotecario',
    propertyTax: 'Contribuciones',
    associationFee: 'Gastos comunes',
    principalInterestLegend: 'Principal e intereses',
    propertyTaxLegend: 'Impuesto predial',
    associationFeeLegend: 'Cuota de asociación',
    insuranceFee: 'Seguro mensual',
    homePrice: 'Precio',
    propertyTaxYearly: 'Contribuciones anuales',
    associationFeeMonthly: 'Gastos comunes mensuales',
    insuranceFeeMonthly: 'Seguro mensual',
    downPayment: 'Pie (%)',
    interestRate: 'Tasa de Interés (%)',
    interestRatePlaceholder: 'Tasa de interés (%)',
    loanType: 'Tipo de crédito',
    loanType10Year: 'Fijo a 10 Años',
    loanType15Year: 'Fijo a 15 Años',
    loanType20Year: 'Fijo a 20 Años',
    loanType30Year: 'Fijo a 30 Años',
    disclaimer: '*Los cálculos son un estimado.'
  },
  marketReport: {
    title: 'Reporte de mercado inmobiliario',
    help:
      'Mediante nuestro informe inmobiliario de Miami y Orlando, podrás seleccionar la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada, comparar valores de inmuebles en diversas zonas, evaluar el impacto de las tendencias e inversiones en el sector y anticipar cambios en el mercado para planificar de manera óptima tus estrategias de compra, venta o arriendo.<br/>' +
      'Con esta herramienta accedes a datos actualizados y detallados, segmentados por comuna, ciudad, código postal y tipo de propiedad, siendo fundamental para tomar decisiones informadas y planificadas en el ámbito inmobiliario. Aprovecha esta valiosa información y revisa el informe completo para optimizar tus inversiones.<br/>',
    longTitle:
      'Reporte de mercado inmobiliario: invierte en propiedades en Miami',
    description:
      'Accede a nuestro reporte de mercado inmobiliario y toma decisiones informadas para invertir en propiedades en Miami y Orlando desde Chile.',
    recentlyRented: 'Recientemente arrendados',
    buyTypes: {
      rentals: 'Arriendos'
    }
  },
  blog: {
    title: 'Oportunidades en Miami y Orlando',
    description:
      'Descubre las mejores oportunidades en Miami y Orlando. Invierte desde Chile y mantente informado sobre las últimas tendencias inmobiliarias.',
    subtitle:
      'Descubre las últimas tendencias en el mercado inmobiliario y mantente informado con nuestras noticias exclusivas. ¡Mantente actualizado sobre el sector y toma decisiones acertadas para tu futuro hogar o inversión!',
    seo: {
      title: 'Oportunidades en Miami y Orlando: Invierte desde Chile con éxito'
    }
  },
  seo: {
    defaultSingle: 'Casa o departamento',
    defaultMultiple: 'Casas y departamentos',
    multipleSale: 'Casas y departamentos en venta',
    multipleRent: 'Casas y departamentos en arriendo',
    defaultDescription:
      'En PFS Realty encuentra bienes raices e inmuebles en venta y arriendo en la Florida.',
    singleRent: '{0} en arriendo',
    singleRentAlt: '{0} en arriendo',
    complementaryTitle:
      'en PFSRealty encuentra bienes raices e inmuebles en venta y arriendo.',
    counties: {
      description:
        'Encuentra propiedades en venta y arriendo en los condados de la Florida.'
    },
    cities: {
      description:
        'Encuentra propiedades en venta y arriendo en las ciudades de {0}, Florida.'
    },
    neighborhoods: {
      description:
        'Encuentra propiedades en venta y arriendo en los vecindarios de {0}, Florida.'
    },
    calculator: {
      longTitle:
        'Simulador crédito hipotecario para comprar en Miami desde Chile',
      description:
        'Usa el simulador crédito hipotecario para calcular el pago mensual y financiar tu casa en Miami desde Chile. ¡Fácil, rápido y con información precisa!'
    }
  }
};
