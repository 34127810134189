export default {
  home: {
    seo: {
      title: 'Bienes raíces en Miami: Compra, venta y renta de propiedades',
      description:
        ' Las mejores opciones de bienes raíces en Miami para comprar, vender o rentar propiedades con confianza y asesoría experta de PFS Realty Group.'
    },
    hero: {
      title: 'Bienes raíces en venta y renta en Miami',
      p: 'Propiedades',
      f: 'Financiamiento',
      s: 'Servicios'
    },
    used: 'Usado',
    new: 'Nuevo',
    rent: 'Rentar',
    buy: 'Usado',
    location: 'Ciudad, código postal o condado',
    currentLocation: 'Ubicación actual',
    contactFormTitle:
      '¡Contacta a uno de nuestros asesores para más información!',
    contactFormText:
      'Accede a nuestra asesoría gratuita de servicios 360° y obtén hasta un 70% de financiamiento en tu inversión.',
    properties_title: 'Nuestro portafolio de propiedades',
    propertiesPortfolio: 'Departamentos y Casas en venta y renta en Miami',
    apartmentsPortfolioDescription: 'Nuestro portafolio de departamentos',
    housesPortfolioDescription: 'Nuestro portafolio de casas',
    find_property_title: 'ENCUENTRA TU PROPIEDAD POR CIUDAD',
    find_property_text:
      'Tenemos un amplio portafolio de propiedades perfectas para ti.',
    clients: {
      title:
        '2000 clientes en todo el mundo han invertido en Miami gracias a PFS Realty',
      features: {
        one: {
          title: '22 AÑOS DE EXPERIENCIA EN VENTAS DE PROPIEDADES EN EE. UU.',
          description:
            'Nuestra prolongada trayectoria como líderes en el mercado de bienes raíces en Miami y Orlando nos posiciona como tu mejor opción para lograr una inversión exitosa desde México. Tenemos un historial comprobado que respalda nuestro servicio confiable y resultados exitosos. A modo de sugerencia, creo pertinente personalizar más cada página según el país.'
        },
        two: {
          title: '65 ASESORES EN 12 PAÍSES DE AMÉRICA Y EUROPA.',
          description:
            'Con una red internacional de agentes, ofrecemos perspectivas globales y localizadas que enriquecen nuestra capacidad para encontrar oportunidades inigualables. Desde el asesoramiento hasta el cierre del trato, optimizamos tus oportunidades de inversión inmobiliaria.'
        },
        three: {
          title: 'MÁS DE 2000 CLIENTES SATISFECHOS CONFÍAN EN NOSOTROS.',
          description:
            'La confianza depositada en nosotros por nuestros clientes es el reflejo de nuestra dedicación y conocimiento del mercado de bienes raíces. Somos uno de los grupos inmobiliarios en Miami y Orlando en Miami más confiables y respetados. ¡Únete y experimenta el éxito con PFS Realty!'
        }
      }
    },
    services: {
      title: 'SERVICIOS INTEGRALES',
      properties: {
        title: 'Adquiere la propiedad ideal',
        description:
          'Encuentra la propiedad perfecta en Miami y compra inmuebles nuevos y usados de alta calidad. Nuestros agentes inmobiliarios cuentan con amplia experiencia y conocimiento para brindarte el asesoramiento especializado que necesitas.'
      },
      financing: {
        title: 'Financiamiento a tu medida',
        description:
          'La compra de un inmueble suele requerir de un financiamiento sólido y adaptado a la capacidad económica del comprador. Por eso, ofrecemos créditos hipotecarios flexibles y personalizados para mexicanos con mínimo 30% de entrada. Adaptamos las condiciones a tus necesidades.'
      },
      extra_income: {
        title: 'Genera ingresos extras alquilando',
        description:
          'Te brindamos una asistencia integral para transformar tu inmueble en una fuente de ingresos en dólares, manejando todas las etapas del arrendamiento para maximizar un retorno de inversión óptimo.'
      },
      management: {
        title: 'Administración de propiedades',
        description:
          'Contamos con un equipo preparado para el cuidado profesional y el mantenimiento preventivo y correctivo de tu vivienda. Optimizamos costos y beneficios para asegurar una administración eficiente en tu inversión inmobiliaria.'
      },
      legal: {
        title: 'Asesoría legal y contable integral',
        description:
          'Nuestro equipo interdisciplinario de profesionales proporciona asesoría experta para aclarar cualquier duda sobre los aspectos legales y contables que involucran la compra, venta y renta de bienes inmuebles. Aseguramos el cumplimiento normativo.'
      }
    },
    events: {
      title: 'Próximos eventos',
      formMessage: 'Déjanos tus datos para separar un cupo en nuestro evento',
      registerButton: 'Reserva Entrada'
    },
    testimonialsTitle: 'Testimoniales',
    news_title: 'Nuestro Blog',
    popupFormTitle:
      'Suscríbete a nuestro newsletter y obtén información de primera mano sobre el mercado inmobiliario en Miami y Orlando.',
    popupFormMessage:
      'Consejos exclusivos, tendencias del mercado y posibilidades únicas. ¡No te pierdas esta oportunidad para impulsar tu éxito en bienes raíces!'
  },
  calculator: {
    title: 'Simulador de crédito hipotecario',
    description:
      'Esta herramienta te permite simular el pago mensual de un préstamo hipotecario, considerando factores como el capital e interés, el predial, la comisión por administración y el seguro mensual. \n' +
      'Al ingresar los datos sobre el precio del inmueble, el enganche y la tasa de interés, puedes obtener un cálculo preciso de tus cuotas mensuales, facilitando la compra y financiamiento de tu vivienda a través de una institución financiera. \n',
    hint: 'Esto es esencial para tu educación financiera y la planificación de la compra de tu vivienda propia, permitiéndote gestionar mejor tu historial crediticio y ahorros.',
    monthlyPayments: 'Cuotas Mensuales',
    principalInterest: 'Capital e Intereses',
    propertyTax: 'Predial',
    associationFee: 'Comisión por Administración',
    principalInterestLegend: 'Capital e Intereses',
    propertyTaxLegend: 'Predial',
    associationFeeLegend: 'Comisión por Administración',
    insuranceFee: 'Seguro Mensual',
    homePrice: 'Precio',
    propertyTaxYearly: 'Predial',
    associationFeeMonthly: 'Comisión por Administración',
    insuranceFeeMonthly: 'Seguro Mensual',
    downPayment: 'Enganche (%)',
    interestRate: 'Tasa de Interés (%)',
    interestRatePlaceholder: 'Tasa de Interés',
    loanType: 'Tipo de Crédito',
    loanType10Year: 'Fijo a 10 Años',
    loanType15Year: 'Fijo a 15 Años',
    loanType20Year: 'Fijo a 20 Años',
    loanType30Year: 'Fijo a 30 Años',
    disclaimer: '*Los cálculos son un estimado.'
  },
  marketReport: {
    help:
      'A través de nuestro reporte del mercado inmobiliario de Miami y Orlando, puedes seleccionar la zona, el tipo de propiedad, y el resto de las opciones para ver la información actualizada, comparar precios en diferentes áreas, evaluar el impacto de las tendencias e inversiones del sector, y anticipar cambios para planificar mejor tus estrategias de compra, venta o alquiler.<br/>' +
      'Esta herramienta permite acceder a datos actualizados y detallados segmentados por condado, ciudad, código postal y tipo de inmueble, siendo fundamental para tomar decisiones informadas y planificadas en el sector de bienes raíces. No te pierdas esta valiosa información y visualiza el reporte completo para optimizar tus inversiones.<br/>' +
      'Seleccione la zona, el tipo de propiedad y el resto de las opciones para ver la información actualizada'
  },
  blog: {
    title: 'Oportunidades en Miami y Orlando',
    subtitle:
      'Descubre las últimas tendencias en el mercado inmobiliario y mantente informado con nuestras noticias exclusivas desde México. ¡No te pierdas ninguna actualización sobre el sector y toma decisiones acertadas para tu futuro hogar o inversión!'
  },
  search: {
    title: 'Búsqueda de casas, departamentos y otros inmuebles',
    properties: 'Casas y departamentos',
    condoType: 'Departamento',
    condosType: 'Departamentos',
    apartmentsCondoType: 'Condominio de departamentos',
    apartment: 'Departamento',
    apartments: 'Departamentos',
    findItems: 'Encuentra {items}'
  },
  project: {
    availableCondosForRent: 'Departamentos disponibles para rentar',
    pendingCondosForRent: 'Departamentos pendientes para rentar',
    closedCondosForRent: 'Departamentos rentados',
    availableCondosForSale: 'Departamentos disponibles para la venta',
    pendingCondosForSale: 'Departamentos pendientes para la venta',
    closedCondosForSale: 'Departamentos vendidos',
    condos:
      '{project} tiene {n} departamento(s) con las opciones seleccionadas con un precio por pie cuadrado promedio de {price}'
  },
  seo: {
    defaultSingle: 'Casa o departamento',
    defaultMultiple: 'Casas y departamentos',
    multipleSale: 'Casas y departamentos en venta',
    multipleRent: 'Casas y departamentos en renta'
  }
};
