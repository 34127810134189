
// @ts-nocheck


export const localeCodes =  [
  "es",
  "us",
  "mx",
  "co",
  "ec",
  "pe",
  "cl"
]

export const localeLoaders = {
  "es": [],
  "us": [],
  "mx": [],
  "co": [],
  "ec": [],
  "pe": [],
  "cl": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "es",
      "name": "Español (Estados Unidos)",
      "iso": "es-US",
      "isCatchallLocale": true
    },
    {
      "code": "us",
      "name": "English (United States)",
      "iso": "en-US"
    },
    {
      "code": "mx",
      "name": "Español (México)",
      "iso": "es-MX"
    },
    {
      "code": "co",
      "name": "Español (Colombia)",
      "iso": "es-CO"
    },
    {
      "code": "ec",
      "name": "Español (Ecuador)",
      "iso": "es-EC"
    },
    {
      "code": "pe",
      "name": "Español (Perú)",
      "iso": "es-PE"
    },
    {
      "code": "cl",
      "name": "Español (Chile)",
      "iso": "es-CL"
    }
  ],
  "defaultLocale": "es",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://dev.pfsrealty.com/",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "index": {
      "us": "/",
      "es": "/",
      "mx": "/",
      "co": "/",
      "ec": "/",
      "pe": "/",
      "cl": "/"
    },
    "homes-and-condos/[...params]": {
      "us": "/homes-and-condos/[...params]",
      "es": "/casas-y-apartamentos/[...params]",
      "mx": "/casas-y-apartamentos/[...params]",
      "co": "/casas-y-apartamentos/[...params]",
      "ec": "/casas-y-apartamentos/[...params]",
      "pe": "/casas-y-apartamentos/[...params]",
      "cl": "/casas-y-apartamentos/[...params]"
    },
    "new-projects/[...params]": {
      "us": "/new-projects/[...params]",
      "es": "/nuevos-proyectos/[...params]",
      "mx": "/nuevos-proyectos/[...params]",
      "co": "/nuevos-proyectos/[...params]",
      "ec": "/nuevos-proyectos/[...params]",
      "pe": "/nuevos-proyectos/[...params]",
      "cl": "/nuevos-proyectos/[...params]"
    },
    "blog/[...params]": {
      "us": "/blog/[...params]",
      "es": "/blog/[...params]",
      "mx": "/blog/[...params]",
      "co": "/blog/[...params]",
      "ec": "/blog/[...params]",
      "pe": "/blog/[...params]",
      "cl": "/blog/[...params]"
    },
    "pfs/[slug]": {
      "us": "/pfs/[slug]",
      "es": "/pfs/[slug]",
      "mx": "/pfs/[slug]",
      "co": "/pfs/[slug]",
      "ec": "/pfs/[slug]",
      "pe": "/pfs/[slug]",
      "cl": "/pfs/[slug]"
    },
    "mortgage-calculator/index": {
      "us": "/mortgage-calculator",
      "es": "/calculadora-de-hipotecas",
      "mx": "/calculadora-de-hipotecas",
      "co": "/calculadora-de-hipotecas",
      "ec": "/calculadora-de-hipotecas",
      "pe": "/calculadora-de-hipotecas",
      "cl": "/calculadora-de-hipotecas"
    },
    "market-report/[...params]": {
      "us": "/market-report/[...params]",
      "es": "/reporte-de-mercado/[...params]",
      "mx": "/reporte-de-mercado/[...params]",
      "co": "/reporte-de-mercado/[...params]",
      "ec": "/reporte-de-mercado/[...params]",
      "pe": "/reporte-de-mercado/[...params]",
      "cl": "/reporte-de-mercado/[...params]"
    },
    "county/index": {
      "us": "/county",
      "es": "/condado",
      "mx": "/condado",
      "co": "/condado",
      "ec": "/condado",
      "pe": "/condado",
      "cl": "/condado"
    },
    "county/[name]/index": {
      "us": "/county/[name]",
      "es": "/condado/[name]",
      "mx": "/condado/[name]",
      "co": "/condado/[name]",
      "ec": "/condado/[name]",
      "pe": "/condado/[name]",
      "cl": "/condado/[name]"
    },
    "elite/index": {
      "us": "/elite",
      "es": "/elite",
      "mx": "/elite",
      "co": "/elite",
      "ec": "/elite",
      "pe": "/elite",
      "cl": "/elite"
    },
    "elite/info": {
      "us": "/elite/info",
      "es": "/elite/info",
      "mx": "/elite/info",
      "co": "/elite/info",
      "ec": "/elite/info",
      "pe": "/elite/info",
      "cl": "/elite/info"
    },
    "elite/thank-you": {
      "us": "/elite/thank-you",
      "es": "/elite/gracias",
      "mx": "/elite/gracias",
      "co": "/elite/gracias",
      "ec": "/elite/gracias",
      "pe": "/elite/gracias",
      "cl": "/elite/gracias"
    },
    "elite/reset-password": {
      "us": "/elite/reset-password",
      "es": "/elite/reiniciar-contrasena",
      "mx": "/elite/reiniciar-contrasena",
      "co": "/elite/reiniciar-contrasena",
      "ec": "/elite/reiniciar-contrasena",
      "pe": "/elite/reiniciar-contrasena",
      "cl": "/elite/reiniciar-contrasena"
    },
    "account/index": {
      "us": "/account",
      "es": "/cuenta",
      "mx": "/cuenta",
      "co": "/cuenta",
      "ec": "/cuenta",
      "pe": "/cuenta",
      "cl": "/cuenta"
    },
    "account/profile": {
      "us": "/account/profile",
      "es": "/cuenta/perfil",
      "mx": "/cuenta/perfil",
      "co": "/cuenta/perfil",
      "ec": "/cuenta/perfil",
      "pe": "/cuenta/perfil",
      "cl": "/cuenta/perfil"
    },
    "account/saved-properties": {
      "us": "/account/saved-properties",
      "es": "/cuenta/propiedades-guardadas",
      "mx": "/cuenta/propiedades-guardadas",
      "co": "/cuenta/propiedades-guardadas",
      "ec": "/cuenta/propiedades-guardadas",
      "pe": "/cuenta/propiedades-guardadas",
      "cl": "/cuenta/propiedades-guardadas"
    },
    "account/saved-searches": {
      "us": "/account/saved-searches",
      "es": "/cuenta/busquedas-guardadas",
      "mx": "/cuenta/busquedas-guardadas",
      "co": "/cuenta/busquedas-guardadas",
      "ec": "/cuenta/busquedas-guardadas",
      "pe": "/cuenta/busquedas-guardadas",
      "cl": "/cuenta/busquedas-guardadas"
    },
    "account/notifications": {
      "us": "/account/notifications",
      "es": "/cuenta/notificaciones",
      "mx": "/cuenta/notificaciones",
      "co": "/cuenta/notificaciones",
      "ec": "/cuenta/notificaciones",
      "pe": "/cuenta/notificaciones",
      "cl": "/cuenta/notificaciones"
    },
    "account/reset-password": {
      "us": "/account/reset-password",
      "es": "/cuenta/reiniciar-contrasena",
      "mx": "/cuenta/reiniciar-contrasena",
      "co": "/cuenta/reiniciar-contrasena",
      "ec": "/cuenta/reiniciar-contrasena",
      "pe": "/cuenta/reiniciar-contrasena",
      "cl": "/cuenta/reiniciar-contrasena"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "es",
    "name": "Español (Estados Unidos)",
    "iso": "es-US",
    "isCatchallLocale": true,
    "files": []
  },
  {
    "code": "us",
    "name": "English (United States)",
    "iso": "en-US",
    "files": []
  },
  {
    "code": "mx",
    "name": "Español (México)",
    "iso": "es-MX",
    "files": []
  },
  {
    "code": "co",
    "name": "Español (Colombia)",
    "iso": "es-CO",
    "files": []
  },
  {
    "code": "ec",
    "name": "Español (Ecuador)",
    "iso": "es-EC",
    "files": []
  },
  {
    "code": "pe",
    "name": "Español (Perú)",
    "iso": "es-PE",
    "files": []
  },
  {
    "code": "cl",
    "name": "Español (Chile)",
    "iso": "es-CL",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
