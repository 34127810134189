<template>
  <NuxtLayout :data-locale-value="localeValue">
    <Teleport to="body">
      <noscript
        v-if="gtmContainerId"
        v-html="
          `<iframe src=&quot;https://www.googletagmanager.com/ns.html?id=${gtmContainerId}&quot; height=&quot;0&quot; width=&quot;0&quot; style=&quot;display: none; visibility: hidden&quot;></iframe>`
        "
      ></noscript>
      <ToastContainer
        class="fixed bottom-[6.5rem] left-1 right-1 md:left-[2.5rem] z-50 min-w-[calc(12vw+180px)] max-w-[calc(10vw+380px)] flex flex-col gap-3"
      />
      <div
        v-if="!cookie && !hideCookiesMessage"
        class="fixed bottom-[1.25rem] md:bottom-[2.25rem] z-[100] bg-neutral text-white absolute-center-x shadow-lg rounded-md w-[calc(100%-32px)] md:w-[calc(100%-64px)] max-w-[1440px] max-w-max"
      >
        <div
          class="relative py-3 px-8 md:py-6 w-full h-full flex max-md:flex-col gap-2 md:gap-4 justify-center items-center"
        >
          <AppButton
            class="icon-button variant-invisible ml-auto h-6 w-6 !absolute right-1 top-1"
            :icon="mdiClose"
            :aria-label="$t('common.closeCookiesMessage')"
            @click="hideCookiesMessage = true"
          />
          <p class="text-center text-xs md:text-sm lg:text-base">
            {{ $t('common.cookiesMessage') }}
          </p>
          <AppButton
            class="px-2 h-[40px] text-sm md:px-4 md:h-[48px] md:text-base rounded"
            @click="cookie = 'true'"
          >
            {{ $t('common.accept') }}
          </AppButton>
        </div>
      </div>
    </Teleport>
    <NuxtLoadingIndicator
      ref="loading"
      class="md:hidden"
      color="#e2007c"
      :height="2"
      :duration="2250"
      :throttle="5"
    />
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import { mdiClose } from '@mdi/js';
import type { User } from '@nhost/vue';

// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
// import useI18nFix from './composables/use-i18n-fix';

import ToastContainer from '@/async-components/Toast/Container.vue';
import { useShare } from '@vueuse/core';

// await useI18nFix();

const {
  public: { gtmContainerId }
} = useRuntimeConfig();

const { locale } = useI18n();

const { loadMainScript } = useHubspot();

useHead({
  title: 'PFS',
  meta: [{ name: 'description', content: 'PFS' }]
});

const localeValue = ref(locale.value);

const cookie = useCookie('cookies-accepted');

const hideCookiesMessage = ref(true);

const route = useRoute();

const user = ref<User | null>(null);
provide('user', user);

function updateWhatsappLinks(projectName: string = '') {
  if (typeof document === 'undefined') {
    return;
  }

  const whatsappLinks = document.querySelectorAll('.whatsapp-link');

  if (!whatsappLinks?.length) {
    return;
  }

  const locationName = projectName
    ? `el%20proyecto%20${projectName.replaceAll(' ', '%20')}`
    : 'Miami';

  const newHref = `https://api.whatsapp.com/send?phone=19545369851&text=Hola,%20Quiero%20invertir%20en%20${locationName}`;
  whatsappLinks.forEach(link => link.setAttribute('href', newHref));
}

provide('updateWhatsappLinks', updateWhatsappLinks);

declare global {
  interface Window {
    dataLayer: unknown[];
    localeCode?: string;
  }
}

function trackPage() {
  if (typeof window === 'undefined') {
    return;
  }
  if (!window.dataLayer || typeof window.dataLayer.push !== 'function') {
    return;
  }
  setTimeout(() => {
    window.dataLayer.push({
      routeName: route.name,
      pageType: 'PageView',
      pageUrl: route.path,
      pageTitle: (typeof document !== 'undefined' && document.title) || '',
      event: 'pageview'
    });
  }, 250);
}

watch(
  () => route.path,
  () => {
    updateWhatsappLinks();
  },
  { immediate: true }
);

watch(
  () => route.path,
  () => {
    trackPage();
  }
);

const { isSupported } = useShare();

onMounted(() => {
  window.localeCode = locale.value;

  hideCookiesMessage.value = false;

  const webShareSupported = isSupported.value;
  // const webShareSupported = navigator?.canShare() || navigator?.userAgentData?.mobile;

  if (webShareSupported) {
    document.body.classList.add('web-share-available');
  }

  loadMainScript();

  updateWhatsappLinks();

  setTimeout(function () {
    const script = document.createElement('script');
    // script.type = 'text/partytown';
    script.src = 'https://website-widgets.pages.dev/dist/sienna.min.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    window.dispatchEvent(new CustomEvent('ptupdate'));
  }, 5000);

  window.nuxt = useNuxtApp();
});
</script>

<style lang="postcss">
@import '@/assets/css/fields.scss';
@import '@/assets/css/fonts.css';
</style>

<style lang="scss">
body {
  background: white;
}

body.web-share-available {
  .web-share-not-supported {
    display: none !important;
  }
}

body:not(.web-share-available) {
  .web-share-supported {
    display: none !important;
  }
}

a.link-primary {
  @apply text-primary;
  &:hover {
    @apply text-primary-dark;
  }
  &:active {
    @apply text-primary-dark;
  }
  &:focus {
    @apply text-primary-dark;
  }
  &:visited {
    @apply text-primary-dark;
  }
}

.modal-enter-active,
.modal-leave-active {
  @apply transition-all duration-300 ease-in-out will-change-transform;
  @apply opacity-100;
  & > * {
    @apply transition-all duration-300 ease-in-out delay-100;
  }
}

.modal-enter-from,
.modal-leave-to {
  @apply opacity-0;
  & > * {
    @apply transform translate-y-[20px];
  }
}

.title-divider {
  @apply relative w-[180px] h-1 bg-blue-dark-saturated;

  &::before {
    @apply absolute left-0 w-1/3 h-full content-[''] bg-yellow-saturated;
  }

  &::after {
    @apply absolute right-0 w-1/3 h-full content-[''] bg-fucsia-saturated;
  }
}

@media (max-width: 559px) {
  .inherit-relative {
    position: unset !important;
  }
}

// floating-vue

.reset-dropdown {
  @apply font-normal text-left;

  .v-popper__inner {
    background: transparent !important;
    color: unset !important;
    border-radius: unset !important;
    border: unset !important;
    box-shadow: unset !important;
    overflow: visible !important;
    z-index: unset !important;
  }

  .v-popper__arrow-container {
    display: none !important;
  }
}

.card-dropdown {
  @apply font-normal text-left;

  &:hover {
    .v-popper__inner {
      @apply shadow-xl #{!important};
    }
  }

  .v-popper__inner {
    @apply transition-all duration-300 ease-in-out;
    @apply bg-white p-0 shadow-lg rounded-lg border-none border-transparent #{!important};
  }

  .v-popper__arrow-outer {
    @apply border-white #{!important};
  }

  .v-popper__arrow-inner {
    visibility: visible;
    @apply border-white #{!important};
  }

  .mini-card {
    @apply shadow-none #{!important};
  }
}

.hubspotForm,
.hbspt-form {
  text-align: left;
  fieldset {
    @apply flex flex-col;
  }
  .form-columns-2 {
    @apply flex flex-row flex-wrap mx-auto;
  }
  .form-columns-1 {
    @apply mx-auto;
  }
  .hs-email,
  .hs-phone {
    @apply w-full #{!important};
  }
  h1,
  h2 {
    @apply text-lg md:text-xl font-bold;
  }
  .field {
    @apply text-sm relative pb-2 #{!important};
    label {
      @apply pl-2;
    }
  }
  .hs-input:not([type='checkbox']):not([type='radio']) {
    @apply w-full border border-neutral-light rounded max-md:h-[36px] h-[42px] px-4 py-1;
  }
  .hs-button {
    @apply w-full bg-primary text-white font-bold px-4 py-2 rounded shadow;
    @apply block text-center min-h-[48px] #{!important};
    @apply cursor-pointer hover:bg-primary-light focus-visible:bg-primary-light hover:bg-primary-light active:bg-primary-lighter;
  }
  ul.inputs-list {
    @apply flex flex-wrap gap-x-4 gap-y-2;
  }
  .hs-form-radio-display {
    @apply flex gap-x-2 items-center;
  }
  .legal-consent-container {
    .hs-richtext {
      @apply text-[12px] text-neutral-light text-center mb-3;
    }
    .hs-form-booleancheckbox-display {
      @apply flex items-center text-sm;
    }
  }
  input[type='checkbox'] {
    @apply accent-primary;
  }
  .hs-form-required {
    @apply text-danger;
  }
  .hs-error-msgs {
    @apply text-xs text-danger;
  }
  a {
    @apply text-primary underline;
  }
}

.asw-menu-btn {
  @apply fixed w-[50px] h-[50px] #{!important};
  @apply bottom-[19px] left-[19px] #{!important};
  @apply min-[480px]:bottom-[33px] min-[480px]:left-[33px] #{!important};
  svg {
    @apply w-[36px] h-[36px] min-w-[36px] min-h-[36px] #{!important};
  }
}

.vel-fade-enter-active,
.vel-fade-leave-active {
  transition: none !important;
  transition-duration: 0 !important;
}
.vel-fade-enter-from,
.vel-fade-leave-to {
  opacity: 1 !important;
}
</style>
